import styled from '@emotion/styled'
import glisseoTheme, { ThemeColor } from '@lib/theme'
import { rgba } from 'polished'

interface CardProps {
  clickable?: boolean
  color?: ThemeColor
  elevation?: number
  hoverable?: boolean
}

const Card = styled.div<CardProps>(
  {
    borderRadius: '15px',
    padding: '30px',
    transition: 'transform 0.3s ease',
    width: '100%',
  },
  ({
    clickable = false,
    color = ThemeColor.White,
    elevation = 1,
    hoverable = false,
    theme = glisseoTheme,
  }) => ({
    backgroundColor: theme.colors[color],
    boxShadow: `0 ${2 * elevation}px ${8 * elevation}px 0 ${rgba(
      theme.colors.black,
      0.2,
    )}`,
    color: [ThemeColor.Light, ThemeColor.White].includes(color)
      ? `${theme.colors.dark} !important`
      : `${theme.colors.light} !important`,
    cursor: clickable ? 'pointer' : 'default',

    '&:hover': {
      transform: hoverable ? 'translateY(-15px)' : 'translateY(0)',
    },

    'h1, h2, h3, h4, h5': {
      color: [ThemeColor.Light, ThemeColor.White].includes(color)
        ? `${theme.colors.black} !important`
        : `${theme.colors.white} !important`,
      cursor: clickable ? 'pointer' : 'default',
    },

    p: {
      cursor: clickable ? 'pointer' : 'default',
    },
  }),
)

export default Card
